*,
::before,
::after {
    box-sizing: border-box;
}

body {
    position: relative;
    /* margin: var(--header-height) 0 0 0; */
    padding: 0 1rem;
    transition: .5s;
}

a {
    text-decoration: none;
}

.header {
    width: 100%;
    height: var(--header-height);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    z-index: var(--z-fixed);
    transition: .5s;
}

.header_toggle {
    color: var(--color-secondary);
    font-size: 1.5rem;
    cursor: pointer;
}

.sidebar {
    position: fixed;
    top: 0;
    left: -30%;
    width: var(--nav-width);
    height: 100vh;
    background-color: var(--black-color);
    padding: .5rem 1rem 0 0;
    transition: .5s;
    z-index: var(--z-fixed);
    
    svg {
        fill: var(--color-primary);
    }
}

.nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
}

.sidebar-logo,
.sidebar-link {
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    column-gap: 1rem;
    padding: .5rem 0 .5rem 1.5rem;
}

.sidebar-logo {
    margin-bottom: 2rem;
}

.sidebar-logo-icon {
    font-size: 1.25rem;
    color: var(--white-color);
}

.sidebar-logo-name {
    color: var(--white-color);
    font-weight: 700;
}

.sidebar-link {
    position: relative;
    color: var(--white-color);
    margin-bottom: 1.5rem;
    transition: .3s;
}

.sidebar-link:hover {
    color: var(--white-color);
}

.sidebar-icon {
    font-size: 1.25rem;
}

.sidebar-name {
    color: var(--white-color) !important;
}

.show-nav{
    left: 0;
}

.body-pd {
    padding-left: calc(var(--nav-width) - 1.5rem);
}

.active svg{
    fill: var(--white-color) !important;
}

.active::before {
    content: '';
    position: absolute;
    left: 0;
    width: 2px;
    height: 32px;
    background-color: var(--white-color);
}

.height-100 {
    height: 100vh;
}

.btn:active {
    outline: none !important;
    border: none !important;
    box-shadow: none;
}

.btn:focus {
    outline:0 !important;
    border: none !important;
}


@media screen and (min-width: 768px) {
    body {
        /* margin: calc(var(--header-height) + 1rem) 0 0 0; */
        /* padding-left: calc(var(--nav-width) + 2rem); */
    }

    .header {
        height: calc(var(--header-height) + 1rem);
        /* padding: 0 2rem 0 calc(var(--nav-width) + 2rem); */
    }

    .header_img {
        width: 40px;
        height: 40px;
    }

    .header_img img {
        width: 45px;
    }

    .sidebar {
        left: 0;
        padding: 1rem 1rem 0 0;
    }

    .show-nav {
        width: calc(var(--nav-width) + 156px);
    }

    .body-pd {
        padding-left: calc(var(--nav-width) + 58px);
    }

    .height-100 {
        padding: 3rem !important;
    }
}