.agent-table {
    /* background-color: transparent !important;
    color: var(--white-color) !important; */
    --bs-table-color: var(--white-color) !important;
    --bs-table-bg: transparent !important;
    --bs-table-hover-color: var(--color-primary);
}

thead, tfoot {
    font: bold;
}

td {
    border: 1px solid white;
    border-collapse: collapse;
}

tr:hover td {
    /* color: var(--white-color) !important; */
    font-weight: bold !important;
    background-color: var(--color-secondary) !important;
}

/* .table-bordered > tbody > tr:nth-of-type(2n+1) > * {
    color: var(--color-primary) !important;
    font-weight: normal !important;
} */


.agent-table-columns tr, th {
    border-top: 0;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 70% !important;
    }
}

.align-middle-table-cell {
    display: flex;
    align-items: center;
}