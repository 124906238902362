:root {
    --color-primary: #f8e616;
    --color-secondary: #5e64a5;
    --color-body: #2f3883;
    --color-alert: #ff1a1a;
    --black-color: #0c0c1e;
    --white-color: #f0f0f0;
    --header-height: 3rem;
    --nav-width: 68px;
    --normal-font-size: 1rem;
    --z-fixed: 100
}

body {
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    scroll-behavior: smooth;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-style: normal;
}

.box-centered {
    max-width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, 50%)
}

.field-login {
    border-radius: 25rem;
}

.button {
    background-color: var(--color-primary);
}

.button:hover {
    color: var(--white-color);
}

.button-logout {
    background-color: var(--color-alert);
}

.link {
    color: var(--color-primary);
    margin: 10px;
    text-decoration: underline;
}

.logo {
    max-width: 300px;
}

.logo-nav {
    height: 40px;
}

#output {
    color: red;
    width: 100%;
    text-align: center;
}

/* h1,
h2 {
    color: var(--color-primary);
    margin-top: 20px;
}

a {
    color: var(--white-color);
}

a:hover {
    color: var(--color-primary);
}

p {
    color: var(--color-primary);
} */

li::marker {
    color: var(--white-color);
}

.nav-bar {
    /* background-color: var(--black-color); */
    background-color: transparent;
    display: flex;
    justify-content: end;
}

.nav-text {
    color: var(--color-primary);
}

.mt-50 {
    margin-top: 100px;
}

#register-form h2 {
    font-size: 1.75rem;
}

@media(min-width:768px) {
    #register-form {
        min-width: 500px;
    }
}

.bg-card {
    background-color: var(--color-secondary);
    border-radius: 5px;
}

textarea {
    height: 120px !important;
}

#contact-card button:focus {
    border: none !important;
}

#btnLogoutSubmit:hover {
    color: var(--color-alert);
}

.nav-link {
    font-size: 0.9rem !important;
    color: var(--color-primary) !important;
}

.text-primary {
    color: var(--color-primary) !important;
}

.btn-close {
    color: var(--color-primary) !important;
}

.card-qtd {
    /* background-image: url('../img/background-card.jpg'); */
    background: linear-gradient(135deg, rgba(12,12,30,1) 0%, rgba(94,100,165,1) 33%, rgba(47,56,131,1) 67%);
}

.btn-icon {
    border: none;
    background: none;
}

.btn-data svg {
    fill: #0dcaf0 !important;
}

.btn-delete svg {
    fill: red !important;
}

.btn-block svg {
    fill: #ffc107 !important;
}

.btn-unblock svg {
    fill: limegreen !important;
}

.btn-search svg {
    fill: var(--white-color) !important;
    color: var(--white-color) !important;
}

.min-width-select {
    min-width: 200px;
}

a:before {
    height: 0 !important;
}

.swal2-container {
    z-index: 1500;
}

#wave {
  position: fixed;
  bottom: 0;
  left:0;
  right: 0;
  z-index: -9999;
}